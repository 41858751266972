a {
  text-decoration: none;
  color: inherit;
}

/*
************************************************************
*
*                       Classes
*
************************************************************
*/

.card {
  margin-bottom: 1rem;
}

.MuiIconButton-label > a {
  height: 1em;
}
